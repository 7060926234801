.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #303030;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.index-banner {
  height: auto;
}

@media (max-width: 1366px) {
  .index-banner {
    height: 350px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 250px;
  }
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  position: relative;
  height: 100%;
}

.index-banner .swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-banner .swiper .swiper-slide h1 {
  font-family: AlibabaPuHuiTiH;
  font-size: 2.8rem;
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide h1 {
    font-size: 1.5rem;
  }
}

.index-banner .swiper .swiper-slide .container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-family: AlibabaPuHuiTiL;
  color: #303030;
}

.index-banner .swiper .swiper-slide .container.title1 {
  top: 2.36rem;
  transform: translateY(0);
  font-family: PangMenZhengDao;
  color: #1c419d;
  font-style: italic;
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title1 {
    top: 150px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title1 {
    top: 50%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title1 {
    transform: translateY(-50%);
  }
}

.index-banner .swiper .swiper-slide .container.title1 p {
  font-size: 46px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .container.title1 p {
    font-size: 42.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title1 p {
    font-size: 39.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .container.title1 p {
    font-size: 36.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .container.title1 p {
    font-size: 33.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title1 p {
    font-size: 30px;
  }
}

.index-banner .swiper .swiper-slide .container.title1 h2 {
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .container.title1 h2 {
    font-size: 28.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title1 h2 {
    font-size: 27.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .container.title1 h2 {
    font-size: 26.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .container.title1 h2 {
    font-size: 25.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title1 h2 {
    font-size: 24px;
  }
}

.index-banner .swiper .swiper-slide .container.title2 {
  font-style: italic;
  top: 1.75rem;
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title2 {
    top: 120px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title2 {
    top: 100px;
  }
}

.index-banner .swiper .swiper-slide .container.title2 h2 {
  font-family: PangMenZhengDao;
  font-size: 42px;
  color: #1c419d;
  margin-top: 0.05rem;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .container.title2 h2 {
    font-size: 39.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title2 h2 {
    font-size: 37.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .container.title2 h2 {
    font-size: 34.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .container.title2 h2 {
    font-size: 32.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title2 h2 {
    font-size: 30px;
  }
}

.index-banner .swiper .swiper-slide .container.title2 p {
  font-family: AlibabaPuHuiTiR;
  font-size: 32px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .container.title2 p {
    font-size: 30.4px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title2 p {
    font-size: 28.8px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .container.title2 p {
    font-size: 27.2px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .container.title2 p {
    font-size: 25.6px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title2 p {
    font-size: 24px;
  }
}

.index-banner .swiper .swiper-slide .container.title3 {
  font-style: italic;
  top: 1.85rem;
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title3 {
    top: 120px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title3 {
    top: 100px;
  }
}

.index-banner .swiper .swiper-slide .container.title3 h2 {
  font-family: PangMenZhengDao;
  font-size: 38px;
  color: #1c419d;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .container.title3 h2 {
    font-size: 35.82857px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title3 h2 {
    font-size: 33.65714px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .container.title3 h2 {
    font-size: 31.48571px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .container.title3 h2 {
    font-size: 29.31429px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title3 h2 {
    font-size: 27.14286px;
  }
}

.index-banner .swiper .swiper-slide .container.title3 p {
  font-family: AlibabaPuHuiTiR;
  font-size: 28px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .container.title3 p {
    font-size: 26.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .container.title3 p {
    font-size: 25.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .container.title3 p {
    font-size: 23.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .container.title3 p {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .container.title3 p {
    font-size: 21px;
  }
}

.index-banner .swiper .pagination {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .pagination {
    bottom: 46px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .pagination {
    bottom: 42px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .pagination {
    bottom: 38px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .pagination {
    bottom: 34px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .pagination {
    bottom: 30px;
  }
}

.index-banner .swiper .pagination .swiper-pagination {
  position: relative;
  width: fit-content;
  left: 0;
  display: flex;
  align-items: center;
}

.index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet {
  margin: 0;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  opacity: 1;
  margin-right: 20px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet {
    margin-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet {
    margin-right: 10px;
  }
}

.index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.index-banner .swiper .pagination .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  height: 20px;
  background: url(./images/pagination-active.png) no-repeat;
}

.index .title {
  font-family: AlibabaPuHuiTiR;
  font-size: 26px;
  letter-spacing: 1px;
  color: #303030;
}

@media (max-width: 1600px) {
  .index .title {
    font-size: 24.8px;
  }
}

@media (max-width: 1366px) {
  .index .title {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  .index .title {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index .title {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .index .title {
    font-size: 20px;
  }
}

.index .index-search {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.index .index-search .left {
  position: relative;
  height: 200px;
  width: 50%;
  background: url(./images/index-search1.png) no-repeat;
  background-size: cover;
  background-position: right;
}

@media (max-width: 1600px) {
  .index .index-search .left {
    height: 190px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .left {
    height: 180px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .left {
    height: 170px;
  }
}

@media (max-width: 991px) {
  .index .index-search .left {
    height: 160px;
  }
}

@media (max-width: 767px) {
  .index .index-search .left {
    height: 150px;
  }
}

@media (max-width: 767px) {
  .index .index-search .left {
    width: 100%;
  }
}

.index .index-search .right {
  height: 200px;
  width: 50%;
  background: url(./images/index-search2.png) no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  background-position: left;
}

@media (max-width: 1600px) {
  .index .index-search .right {
    height: 190px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .right {
    height: 180px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .right {
    height: 170px;
  }
}

@media (max-width: 991px) {
  .index .index-search .right {
    height: 160px;
  }
}

@media (max-width: 767px) {
  .index .index-search .right {
    height: 150px;
  }
}

@media (max-width: 767px) {
  .index .index-search .right {
    width: 100%;
  }
}

.index .index-search .right .text {
  margin-left: 210px;
}

@media (max-width: 1600px) {
  .index .index-search .right .text {
    margin-left: 2.1rem;
  }
}

@media (max-width: 767px) {
  .index .index-search .right .text {
    margin-left: 10px;
  }
}

.index .index-search .right h1 {
  font-family: AlibabaPuHuiTiL;
  font-size: 60px;
  letter-spacing: 2px;
  color: #bebebe;
}

@media (max-width: 1600px) {
  .index .index-search .right h1 {
    font-size: 56px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .right h1 {
    font-size: 52px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .right h1 {
    font-size: 48px;
  }
}

@media (max-width: 991px) {
  .index .index-search .right h1 {
    font-size: 44px;
  }
}

@media (max-width: 767px) {
  .index .index-search .right h1 {
    font-size: 40px;
  }
}

.index .index-search .right h2 {
  font-family: AlibabaPuHuiTiL;
  font-size: 26px;
  letter-spacing: 1px;
  color: #303030;
}

@media (max-width: 1600px) {
  .index .index-search .right h2 {
    font-size: 24.8px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .right h2 {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .right h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index .index-search .right h2 {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .index .index-search .right h2 {
    font-size: 20px;
  }
}

.index .index-search .container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.index .index-search .container h2 {
  font-family: AlibabaPuHuiTiL;
  font-size: 32px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 30px;
  margin-bottom: 24px;
}

@media (max-width: 1600px) {
  .index .index-search .container h2 {
    font-size: 30.4px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .container h2 {
    font-size: 28.8px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .container h2 {
    font-size: 27.2px;
  }
}

@media (max-width: 991px) {
  .index .index-search .container h2 {
    font-size: 25.6px;
  }
}

@media (max-width: 767px) {
  .index .index-search .container h2 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .index .index-search .container h2 {
    padding-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .container h2 {
    padding-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .container h2 {
    padding-top: 21px;
  }
}

@media (max-width: 991px) {
  .index .index-search .container h2 {
    padding-top: 18px;
  }
}

@media (max-width: 767px) {
  .index .index-search .container h2 {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index .index-search .container h2 {
    margin-bottom: 21.6px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .container h2 {
    margin-bottom: 19.2px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .container h2 {
    margin-bottom: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .index-search .container h2 {
    margin-bottom: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .index-search .container h2 {
    margin-bottom: 12px;
  }
}

.index .index-search .container .search-box {
  width: 400px;
  position: relative;
}

@media (max-width: 991px) {
  .index .index-search .container .search-box {
    width: 300px;
  }
}

.index .index-search .container .search-box input[type="text"] {
  width: 400px;
  height: 46px;
  border: solid 1px #ffffff;
  border-radius: 4px;
  font-family: AlibabaPuHuiTiL;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  outline: none;
  background: transparent;
  padding-left: 20px;
  box-sizing: border-box;
}

@media (max-width: 991px) {
  .index .index-search .container .search-box input[type="text"] {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .index .index-search .container .search-box input[type="text"] {
    height: 36px;
  }
}

@media (max-width: 1600px) {
  .index .index-search .container .search-box input[type="text"] {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index .index-search .container .search-box input[type="text"] {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index .index-search .container .search-box input[type="text"] {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index .index-search .container .search-box input[type="text"] {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index .index-search .container .search-box input[type="text"] {
    padding-left: 10px;
  }
}

.index .index-search .container .search-box input[type="text"]::placeholder {
  font-family: AlibabaPuHuiTiL;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.index .index-search .container .search-box input[type="submit"] {
  width: 20px;
  height: 20px;
  background: url(./images/search-icon1.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}

.index .index-search .container p {
  margin-top: 15px;
  font-family: AlibabaPuHuiTiL;
  font-size: 14px;
  color: #ffffff;
}

.index .section1 {
  width: fit-content;
  margin: auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #303030;
  letter-spacing: 1px;
}

@media (max-width: 1024px) {
  .index .section1 {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    width: 96%;
  }
}

@media (max-width: 1600px) {
  .index .section1 {
    padding-top: 88px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    padding-top: 76px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    padding-top: 64px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    padding-top: 52px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    padding-top: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section1 {
    padding-bottom: 88px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    padding-bottom: 76px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    padding-bottom: 64px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    padding-bottom: 52px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    padding-bottom: 40px;
  }
}

.index .section1 h2 {
  font-family: AlibabaPuHuiTiR;
  font-size: 26px;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index .section1 h2 {
    font-size: 24.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 h2 {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 h2 {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 h2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section1 h2 {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index .section1 h2 {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index .section1 h2 {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index .section1 h2 {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index .section1 h2 {
    margin-bottom: 15px;
  }
}

.index .section1 p {
  font-family: AlibabaPuHuiTiL;
  font-size: 16px;
  line-height: 30px;
  color: #505050;
}

@media (max-width: 1600px) {
  .index .section1 p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section1 p {
    line-height: 29px;
  }
}

@media (max-width: 1366px) {
  .index .section1 p {
    line-height: 28px;
  }
}

@media (max-width: 1024px) {
  .index .section1 p {
    line-height: 27px;
  }
}

@media (max-width: 991px) {
  .index .section1 p {
    line-height: 26px;
  }
}

@media (max-width: 767px) {
  .index .section1 p {
    line-height: 25px;
  }
}

.index .section1 a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  background-color: #1c419d;
  border-radius: 4px;
  font-family: AlibabaPuHuiTiL;
  font-size: 16px;
  color: #fefefe;
  letter-spacing: 1px;
  margin: auto;
  margin-top: 40px;
}

@media (max-width: 1600px) {
  .index .section1 a {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 a {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 a {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 a {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 a {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section1 a {
    margin-top: 38px;
  }
}

@media (max-width: 1366px) {
  .index .section1 a {
    margin-top: 36px;
  }
}

@media (max-width: 1024px) {
  .index .section1 a {
    margin-top: 34px;
  }
}

@media (max-width: 991px) {
  .index .section1 a {
    margin-top: 32px;
  }
}

@media (max-width: 767px) {
  .index .section1 a {
    margin-top: 30px;
  }
}

.index .section2 {
  background: url(./images/index-probg.png) no-repeat;
  background-size: cover;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .index .section2 {
    padding-top: 70px;
  }
}

@media (max-width: 1366px) {
  .index .section2 {
    padding-top: 60px;
  }
}

@media (max-width: 1024px) {
  .index .section2 {
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .index .section2 {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .index .section2 {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section2 {
    padding-bottom: 88px;
  }
}

@media (max-width: 1366px) {
  .index .section2 {
    padding-bottom: 76px;
  }
}

@media (max-width: 1024px) {
  .index .section2 {
    padding-bottom: 64px;
  }
}

@media (max-width: 991px) {
  .index .section2 {
    padding-bottom: 52px;
  }
}

@media (max-width: 767px) {
  .index .section2 {
    padding-bottom: 40px;
  }
}

.index .section2 .content {
  margin-top: 0;
  margin-left: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .index .section2 .content {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content {
    flex-wrap: wrap;
  }
}

.index .section2 .content .pic {
  width: 500px;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .index .section2 .content .pic {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .pic {
    height: auto;
  }
}

.index .section2 .content .pic img {
  width: 100%;
  margin: auto;
  display: none;
}

@media (max-width: 767px) {
  .index .section2 .content .pic img {
    width: 80%;
  }
}

.index .section2 .content .pic img.active {
  display: block;
}

.index .section2 .content .nav-box {
  display: none;
  overflow-y: hidden;
  overflow-x: auto;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .index .section2 .content .nav-box {
    display: block;
  }
}

.index .section2 .content .nav-box .pro-phonenav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
}

.index .section2 .content .nav-box .pro-phonenav a {
  display: block;
  font-size: 20px;
  letter-spacing: 1px;
  color: #303030;
  white-space: nowrap;
  margin-right: 10px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section2 .content .nav-box .pro-phonenav a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .nav-box .pro-phonenav a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .nav-box .pro-phonenav a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .nav-box .pro-phonenav a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .nav-box .pro-phonenav a {
    font-size: 16px;
  }
}

.index .section2 .content .nav-box .pro-phonenav a.active {
  color: #1c419d;
}

.index .section2 .content .pro-nav {
  width: 420px;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1600px) {
  .index .section2 .content .pro-nav {
    margin-top: 52px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .pro-nav {
    margin-top: 44px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .pro-nav {
    margin-top: 36px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .pro-nav {
    margin-top: 28px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .pro-nav {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .pro-nav {
    display: none;
  }
}

.index .section2 .content .pro-nav a {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  border-radius: 8px;
  border: solid 1px #d2d2d2;
  transition: all .3s;
  font-family: AlibabaPuHuiTiL;
  font-size: 20px;
  letter-spacing: 1px;
  color: #303030;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index .section2 .content .pro-nav a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .pro-nav a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .pro-nav a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .pro-nav a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .pro-nav a {
    font-size: 16px;
  }
}

.index .section2 .content .pro-nav a:hover, .index .section2 .content .pro-nav a.active {
  background-color: #1c419d;
  border-color: #1c419d;
  color: #fff;
}

.index .section3 {
  width: 100%;
  height: 600px;
  position: relative;
  display: block;
}

@media (max-width: 767px) {
  .index .section3 {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index .section3 {
    display: none;
  }
}

.index .section3 .model {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.index .section3 .ty {
  display: block;
  position: absolute;
  width: 12.96rem;
  height: 100%;
  background: url(./images/index-ty.png) no-repeat;
  background-position: right;
  z-index: 3;
}

@media (max-width: 767px) {
  .index .section3 .ty {
    display: none;
  }
}

.index .section3 .addbg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.index .section3 .addbg img {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index .section3 .addbg img.active {
  display: block;
}

.index .section3 .container {
  position: relative;
  z-index: 5;
  color: #fff;
}

.index .section3 .container .title {
  color: #fff;
  padding-top: 80px;
}

@media (max-width: 1600px) {
  .index .section3 .container .title {
    padding-top: 70px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .title {
    padding-top: 60px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .title {
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .title {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .title {
    padding-top: 30px;
  }
}

.index .section3 .container .introduce {
  margin-top: 130px;
  width: 500px;
}

.index .section3 .container .introduce .content {
  display: none;
}

.index .section3 .container .introduce .content.active {
  display: block;
}

.index .section3 .container .introduce .content .pic {
  width: 80px;
  height: 80px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content .pic {
    width: 76px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content .pic {
    width: 72px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content .pic {
    width: 68px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content .pic {
    width: 64px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content .pic {
    width: 60px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content .pic {
    height: 76px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content .pic {
    height: 72px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content .pic {
    height: 68px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content .pic {
    height: 64px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content .pic {
    height: 60px;
  }
}

.index .section3 .container .introduce .content h2 {
  font-family: AlibabaPuHuiTiR;
  font-size: 24px;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content h2 {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content h2 {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content h2 {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content h2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content h2 {
    margin-top: 19px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content h2 {
    margin-top: 18px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content h2 {
    margin-top: 17px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content h2 {
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content h2 {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content h2 {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content h2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content h2 {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content h2 {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content h2 {
    margin-bottom: 10px;
  }
}

.index .section3 .container .introduce .content p {
  font-family: AlibabaPuHuiTiL;
  font-size: 16px;
  line-height: 30px;
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container .introduce .content p {
    line-height: 28.8px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container .introduce .content p {
    line-height: 27.6px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container .introduce .content p {
    line-height: 26.4px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container .introduce .content p {
    line-height: 25.2px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container .introduce .content p {
    line-height: 24px;
  }
}

.index .section3 .add-nav {
  display: block;
  position: absolute;
  z-index: 6;
  left: 12.64rem;
  top: 90px;
}

@media (max-width: 767px) {
  .index .section3 .add-nav {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index .section3 .add-nav {
    left: 12.6rem;
  }
}

@media (max-width: 1366px) {
  .index .section3 .add-nav {
    left: 12.56rem;
  }
}

@media (max-width: 991px) {
  .index .section3 .add-nav {
    left: 12.5rem;
  }
}

.index .section3 .add-nav ul li {
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 70px;
}

.index .section3 .add-nav ul li:nth-child(1) {
  margin-left: -0.14rem;
}

.index .section3 .add-nav ul li:nth-child(2) {
  margin-left: 0.08rem;
}

.index .section3 .add-nav ul li:nth-child(3) {
  margin-left: 0.16rem;
}

.index .section3 .add-nav ul li:nth-child(4) {
  margin-left: 0.08rem;
}

.index .section3 .add-nav ul li:last-child {
  margin-left: -0.14rem;
}

.index .section3 .add-nav ul li .span {
  width: 30px;
  height: 30px;
  border: solid 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all .3s;
}

.index .section3 .add-nav ul li .span span {
  display: block;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
}

.index .section3 .add-nav ul li a {
  display: block;
  font-family: AlibabaPuHuiTiL;
  font-size: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-left: 24px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section3 .add-nav ul li a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .add-nav ul li a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .add-nav ul li a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .add-nav ul li a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .add-nav ul li a {
    font-size: 16px;
  }
}

.index .section3 .add-nav ul li.active .span {
  background: #1c419d;
  border-color: #1c419d;
}

.index .section3 .add-nav ul li.active a {
  font-family: AlibabaPuHuiTiR;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .index .section3 .add-nav ul li.active a {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .add-nav ul li.active a {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .add-nav ul li.active a {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .add-nav ul li.active a {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .add-nav ul li.active a {
    font-size: 20px;
  }
}

.index .add-ph {
  margin-top: 40px;
  display: none;
}

@media (max-width: 767px) {
  .index .add-ph {
    display: block;
  }
}

.index .add-ph ul {
  margin-top: 20px;
}

.index .add-ph ul li {
  color: #303030;
  margin-bottom: 20px;
}

.index .add-ph ul li .bg {
  position: relative;
}

.index .add-ph ul li .bg .model {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.index .add-ph ul .content {
  width: 100%;
  margin-top: 15px;
}

.index .add-ph ul .content .top {
  display: flex;
  align-items: center;
}

.index .add-ph ul .content .top .pic {
  width: 80px;
  height: 80px;
  border: solid 1px #1c419d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1600px) {
  .index .add-ph ul .content .top .pic {
    width: 76px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content .top .pic {
    width: 72px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content .top .pic {
    width: 68px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content .top .pic {
    width: 64px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content .top .pic {
    width: 60px;
  }
}

@media (max-width: 1600px) {
  .index .add-ph ul .content .top .pic {
    height: 76px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content .top .pic {
    height: 72px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content .top .pic {
    height: 68px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content .top .pic {
    height: 64px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content .top .pic {
    height: 60px;
  }
}

.index .add-ph ul .content .top .pic img {
  width: fit-content;
  height: auto;
}

.index .add-ph ul .content .top h2 {
  font-family: AlibabaPuHuiTiR;
  font-size: 24px;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index .add-ph ul .content .top h2 {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content .top h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content .top h2 {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content .top h2 {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content .top h2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index .add-ph ul .content .top h2 {
    margin-top: 19px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content .top h2 {
    margin-top: 18px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content .top h2 {
    margin-top: 17px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content .top h2 {
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content .top h2 {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index .add-ph ul .content .top h2 {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content .top h2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content .top h2 {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content .top h2 {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content .top h2 {
    margin-bottom: 10px;
  }
}

.index .add-ph ul .content p {
  font-family: AlibabaPuHuiTiL;
  font-size: 16px;
  line-height: 30px;
}

@media (max-width: 1600px) {
  .index .add-ph ul .content p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .add-ph ul .content p {
    line-height: 28.8px;
  }
}

@media (max-width: 1366px) {
  .index .add-ph ul .content p {
    line-height: 27.6px;
  }
}

@media (max-width: 1024px) {
  .index .add-ph ul .content p {
    line-height: 26.4px;
  }
}

@media (max-width: 991px) {
  .index .add-ph ul .content p {
    line-height: 25.2px;
  }
}

@media (max-width: 767px) {
  .index .add-ph ul .content p {
    line-height: 24px;
  }
}

.index .section4 {
  background: url(./images/index-newsbg.png) no-repeat;
  background-size: cover;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 60px;
}

@media (max-width: 1600px) {
  .index .section4 {
    padding-top: 70px;
  }
}

@media (max-width: 1366px) {
  .index .section4 {
    padding-top: 60px;
  }
}

@media (max-width: 1024px) {
  .index .section4 {
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .index .section4 {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .index .section4 {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section4 {
    padding-bottom: 54px;
  }
}

@media (max-width: 1366px) {
  .index .section4 {
    padding-bottom: 48px;
  }
}

@media (max-width: 1024px) {
  .index .section4 {
    padding-bottom: 42px;
  }
}

@media (max-width: 991px) {
  .index .section4 {
    padding-bottom: 36px;
  }
}

@media (max-width: 767px) {
  .index .section4 {
    padding-bottom: 30px;
  }
}

.index .section4 .container {
  position: relative;
}

.index .section4 .container .news .more {
  position: absolute;
  top: -10px;
  right: 0;
  font-family: AlibabaPuHuiTiL;
  font-size: 20px;
  color: #a7a7a7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: solid 1px #a7a7a7;
  transition: all .3s;
}

@media (max-width: 767px) {
  .index .section4 .container .news .more {
    right: 10px;
  }
}

.index .section4 .container .news .more:hover {
  color: #1c419d;
  border-color: #1c419d;
}

.index .section4 .container .news ul {
  margin-top: 40px;
}

.index .section4 .container .news ul li {
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li {
    margin-bottom: 20px;
  }
}

.index .section4 .container .news ul li:last-child {
  margin-bottom: 0;
}

.index .section4 .container .news ul li a {
  display: flex;
  align-items: stretch;
}

.index .section4 .container .news ul li .time {
  color: #c5c5c5;
  transition: all .3s;
  margin-right: 64px;
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li .time {
    margin-right: 57.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li .time {
    margin-right: 51.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li .time {
    margin-right: 44.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li .time {
    margin-right: 38.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .time {
    margin-right: 32px;
  }
}

.index .section4 .container .news ul li .time h2 {
  font-family: OriginalSurfer-Regular;
  font-size: 60px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 3px;
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li .time h2 {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li .time h2 {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li .time h2 {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li .time h2 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .time h2 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li .time h2 {
    line-height: 45px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li .time h2 {
    line-height: 40px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li .time h2 {
    line-height: 35px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li .time h2 {
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .time h2 {
    line-height: 25px;
  }
}

.index .section4 .container .news ul li .time span {
  font-family: OriginalSurfer-Regular;
  font-size: 16px;
  letter-spacing: 1px;
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li .time span {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li .time span {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li .time span {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li .time span {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .time span {
    font-size: 14px;
  }
}

.index .section4 .container .news ul li .text {
  font-family: AlibabaPuHuiTiL;
  color: #7b7b7b;
  transition: all .3s;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  flex: 1;
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .text {
    width: 50%;
  }
}

.index .section4 .container .news ul li .text h2 {
  font-size: 20px;
  letter-spacing: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li .text h2 {
    font-size: 19.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li .text h2 {
    font-size: 19.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li .text h2 {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li .text h2 {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .text h2 {
    font-size: 18px;
  }
}

.index .section4 .container .news ul li .text p {
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1600px) {
  .index .section4 .container .news ul li .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .news ul li .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .news ul li .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .news ul li .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .news ul li .text p {
    font-size: 14px;
  }
}

.index .section4 .container .news ul li:hover .time {
  color: #1c419d;
}

.index .section4 .container .news ul li:hover .text {
  color: #303030;
  border-color: #1c419d;
}

.index .section4 .container .notice {
  margin-top: 30px;
  height: 112px;
  display: flex;
  align-items: center;
}

@media (max-width: 1600px) {
  .index .section4 .container .notice {
    height: 101.6px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .notice {
    height: 91.2px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .notice {
    height: 80.8px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .notice {
    height: 70.4px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .notice {
    height: 60px;
  }
}

.index .section4 .container .notice span {
  display: block;
  font-family: AlibabaPuHuiTiR;
  font-size: 26px;
  margin-right: 35px;
}

@media (max-width: 1600px) {
  .index .section4 .container .notice span {
    font-size: 24.8px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .notice span {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .notice span {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .notice span {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .notice span {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section4 .container .notice span {
    margin-right: 32px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .notice span {
    margin-right: 29px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .notice span {
    margin-right: 26px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .notice span {
    margin-right: 23px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .notice span {
    margin-right: 20px;
  }
}

.index .section4 .container .notice .swiper {
  letter-spacing: 1px;
  color: #303030;
  height: 25px;
  line-height: 25px;
  flex: 1;
}

.index .section4 .container .notice .swiper .swiper-slide {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.index .section4 .container .notice .swiper .swiper-slide a {
  font-family: AlibabaPuHuiTiL;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index .section4 .container .notice .swiper .swiper-slide a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index .section4 .container .notice .swiper .swiper-slide a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index .section4 .container .notice .swiper .swiper-slide a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index .section4 .container .notice .swiper .swiper-slide a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index .section4 .container .notice .swiper .swiper-slide a {
    font-size: 16px;
  }
}
