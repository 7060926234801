@import "../../assets/styles/utils";
.index-banner {
    @include res(height,auto,(mmd:350px,xs:250px));
    .swiper {
        height: 100%;
        .swiper-slide {
            position: relative;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            h1 {
                font-family: AlibabaPuHuiTiH;
                @include res(font-size,2.8rem,(xs:1.5rem));
                color: #ffffff;
                opacity: 0.6;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
            .container {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                font-family: AlibabaPuHuiTiL;
                color: #303030;
                &.title1 {
                    @include res(top,2.36rem,(mmd:150px,xs:50%));
                    @include res(transform,translateY(0),(xs:translateY(-50%)));
                    font-family: PangMenZhengDao;
                    color: #1c419d;
                    font-style: italic;
                    p {
                        @include res(font-size,46px,(30 / 46));
                        
                    }
                    h2 {
                        @include res(font-size,30px,(24 / 30));
                    }
                }
                &.title2 {
                    font-style: italic;
                    @include res(top,1.75rem,(mmd:120px,xs:100px));
                    h2 {
                        font-family: PangMenZhengDao;
                        @include res(font-size,42px,(30 / 42));
                        color: #1c419d;
                        margin-top: 0.05rem;
                    }
                    p {
                        font-family: AlibabaPuHuiTiR;
                        @include res(font-size,32px,(24 / 32));
                    }
                }
                &.title3 {
                    font-style: italic;
                    @include res(top,1.85rem,(mmd:120px,xs:100px));
                    h2 {
                        font-family: PangMenZhengDao;
                        @include res(font-size,38px,(30 / 42));
                        color: #1c419d;
                    }
                    p {
                        font-family: AlibabaPuHuiTiR;
                        @include res(font-size,28px,(24 / 32));
                    }
                }
            }
        }
        .pagination {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            @include res(bottom,50px,(3 / 5));
            
            .swiper-pagination {
                position: relative;
                width: fit-content;
                left: 0;
                display: flex;
                align-items: center;
                .swiper-pagination-bullet {
                    margin: 0;
                    width: 10px;
                    height: 10px;
                    background-color: #ffffff;
                    opacity: 1;
                    @include res(margin-right,20px,(1 / 2));
                    &:last-child {
                        margin-right: 0;
                    }
                    &.swiper-pagination-bullet-active {
                        width: 20px;
                        height: 20px;
                        background: url(./images/pagination-active.png)no-repeat;
                    }
                }
            }
        }
    }
}
.index {
    .title {
        font-family: AlibabaPuHuiTiR;
        @include res(font-size,26px,(20 / 26));
        letter-spacing: 1px;
        color: #303030;
    }
    .index-search {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        .left {
            position: relative;
            @include res(height,200px,(3 / 4));
            @include res(width,50%,(xs:100%));
            background: url(./images/index-search1.png)no-repeat;
            background-size: cover;
            background-position: right;
        }
        .right {
            @include res(height,200px,(3 / 4));
            @include res(width,50%,(xs:100%));
            background: url(./images/index-search2.png)no-repeat;
            background-size: cover;
            position: relative;
            z-index: 3;
            display: flex;
            align-items: center;
            background-position: left;
            .text {
                @include res(margin-left,210px,(lg:2.1rem,xs:10px));
            }
            h1 {
                font-family: AlibabaPuHuiTiL;
                @include res(font-size,60px,(2 / 3));
                letter-spacing: 2px;
                color: #bebebe;
            }
            h2 {
                font-family: AlibabaPuHuiTiL;
                @include res(font-size,26px,(20 / 26));
                letter-spacing: 1px;
                color: #303030;
            }
        }
        .container {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            h2 {
                font-family: AlibabaPuHuiTiL;
                @include res(font-size,32px,(24 / 32));
                letter-spacing: 1px;
                color: #ffffff;
                @include res(padding-top,30px,(1 / 2));
                @include res(margin-bottom,24px,(1 / 2));
            }
            .search-box {
                @include res(width,400px,(sm:300px));
                position: relative;
                input {
                    &[type="text"] {
                        @include res(width,400px,(sm:300px));
                        @include res(height,46px,(sm:36px));
                        border: solid 1px #ffffff;
                        border-radius: 4px;
                        font-family: AlibabaPuHuiTiL;
                        font-size: 14px;
                        color: rgba(255,255,255,.4);
                        outline: none;
                        background: transparent;
                        @include res(padding-left,20px,(1 / 2));
                        box-sizing: border-box;
                        &::placeholder {
                            font-family: AlibabaPuHuiTiL;
                            font-size: 14px;
                            color: rgba(255,255,255,.4);
                        }
                    }
                }
                input {
                    &[type="submit"] {
                        width: 20px;
                        height: 20px;
                        background: url(./images/search-icon1.png)no-repeat;
                        background-size: cover;
                        position: absolute;
                        right: 20px;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        padding: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    } 
                }
            }
            p {
                margin-top: 15px;
                font-family: AlibabaPuHuiTiL;
                font-size: 14px;
                color: #ffffff;
            }
        }
    }
    .section1 {
        @include res(width,fit-content,(md:90%,xs:96%));
        margin: auto;
        text-align: center;
        @include res(padding-top,100px,(2 / 5));
        @include res(padding-bottom,100px,(2 / 5));
        color: #303030;
        letter-spacing: 1px;
        h2 {
            font-family: AlibabaPuHuiTiR;
            @include res(font-size,26px,(20 / 26));
            @include res(margin-bottom,30px,(1 / 2));
        }
        p {
            font-family: AlibabaPuHuiTiL;
            @include res(font-size,16px,(14 / 16));
            @include res(line-height,30px,(5 / 6));
            color: #505050;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
	        height: 40px;
            background-color: #1c419d;
            border-radius: 4px;
            font-family: AlibabaPuHuiTiL;
            @include res(font-size,16px,(14 / 16));
            color: #fefefe;
            letter-spacing: 1px;
            margin: auto;
            @include res(margin-top,40px,(3 / 4));
        }
    }
    .section2 {
        background: url(./images/index-probg.png)no-repeat;
        background-size: cover;
        width: 100%;
        @include res(padding-top,80px,(3 / 8));
        @include res(padding-bottom,100px,(2 / 5));
        .content {
            @include res(margin-top,0,(xs:20px));
            @include res(margin-left,100px,(xs:10px));
            display: flex;
            justify-content: space-between;
            @include res(flex-wrap,nowrap,(xs:wrap));
            .pic {
                @include res(width,500px,(xs:100%));
                @include res(height,380px,(xs:auto));
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    @include res(width,100%,(xs:80%));
                    margin: auto;
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }
            .nav-box {
                @include res(display,none,(xs:block));
                overflow-y: hidden;
                overflow-x: auto;
                height: 40px;
                width: 100%;
                margin-bottom: 20px;
                .pro-phonenav {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: fit-content;
                    a {
                        display: block;
                        @include res(font-size,20px,(16 / 20));
                        letter-spacing: 1px;
                        color: #303030;
                        white-space: nowrap;
                        margin-right: 10px;
                        transition: all .3s;
                        &.active {
                            color: #1c419d;
                        }
                    }
                }
            }
            .pro-nav {
                @include res(width,420px);
                @include res(margin-top,60px,(1 / 3));
                @include res(display,flex,(xs:none));
                flex-wrap: wrap;
                justify-content: space-between;
                a {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include res(width,200px);
                    @include res(height,60px);
                    border-radius: 8px;
                    border: solid 1px #d2d2d2;
                    transition: all .3s;
                    font-family: AlibabaPuHuiTiL;
                    @include res(font-size,20px,(16 / 20));
                    letter-spacing: 1px;
                    color: #303030;
                    margin-bottom: 20px;
                    &:hover,&.active {
                        background-color: #1c419d;
                        border-color: #1c419d;
                        color: #fff;
                    }
                }
            }
        }
    }
    .section3 {
        width: 100%;
        @include res(height,600px,(xs:auto));
        position: relative;
        @include res(display,block,(xs:none));
        .model {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0,0,0,.2);
            z-index: 2;
        }
        .ty {
            @include res(display,block,(xs:none));
            position: absolute;
            @include res(width,12.96rem);
            height: 100%;
            background: url(./images/index-ty.png)no-repeat;
            background-position: right;
            z-index: 3;
        }
        .addbg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            img {
                display: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
                &.active {
                    display: block;
                }
            }
        }
        .container {
            position: relative;
            z-index: 5;
            color: #fff;
            .title {
                color: #fff;
                @include res(padding-top,80px,(3 / 8));
            }
            .introduce {
                @include res(margin-top,130px);
                @include res(width,500px);
                .content {
                    display: none;
                    &.active {
                        display: block;
                    }
                    .pic {
                        @include res(width,80px,(3 / 4));
                        @include res(height,80px,(3 / 4));
                        border: solid 1px rgba(255,255,255,.5);
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    h2 {
                        font-family: AlibabaPuHuiTiR;
                        @include res(font-size,24px,(20 / 24));
                        letter-spacing: 1px;
                        @include res(margin-top,20px,(3 /4));
                        @include res(margin-bottom,15px,(2 / 3));
                    }
                    p {
                        font-family: AlibabaPuHuiTiL;
                        @include res(font-size,16px,(14 / 16));
                        @include res(line-height,30px,(4 / 5));
                    }
                }
            } 
        }
        .add-nav {
            @include res(display,block,(xs:none));
            position: absolute;
            z-index: 6;
            @include res(left,12.64rem,(lg:12.6rem,mmd:12.56rem,sm:12.5rem));
            @include res(top,90px);
            ul {
                li {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    @include res(margin-bottom,70px);
                    &:nth-child(1) {
                        margin-left: -0.14rem;
                    }
                    &:nth-child(2) {
                        margin-left: 0.08rem;
                    }
                    &:nth-child(3) {
                        margin-left: 0.16rem;
                    }
                    &:nth-child(4) {
                        margin-left: 0.08rem;
                    }
                    &:last-child {
                        margin-left: -0.14rem;
                    }
                    .span {
                        width: 30px;
                        height: 30px;
                        border: solid 1px #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        transition: all .3s;
                        span {
                            display: block;
                            width: 10px;
                            height: 10px;
                            background: #fff;
                            border-radius: 50%;
                        }
                    }
                    a {
                        display: block;
                        font-family: AlibabaPuHuiTiL;
                        @include res(font-size,20px,(16 / 20));
                        letter-spacing: 1px;
                        color: #ffffff;
                        margin-left: 24px;
                        transition: all .3s;
                    }
                    &.active {
                        .span {
                            background: #1c419d;
                            border-color: #1c419d;
                        }
                        a {
                            font-family: AlibabaPuHuiTiR;
                            @include res(font-size,24px,(20 / 24));
                        }
                    }
                }
            }
        }
    }
    .add-ph {
        margin-top: 40px;
        @include res(display,none,(xs:block));
        ul {
            margin-top: 20px;
            li {
                color: #303030;
                margin-bottom: 20px;
                .bg {
                    position: relative;
                    .model {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0,.2);
                        top: 0;
                        left: 0;
                    }
                }
            }
            .content {
                width: 100%;
                margin-top: 15px;
                .top {
                    display: flex;
                    align-items: center;
                    .pic {
                        @include res(width,80px,(3 / 4));
                        @include res(height,80px,(3 / 4));
                        border: solid 1px #1c419d;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        img {
                            width: fit-content;
                            height: auto;
                        }
                    }
                    h2 {
                        font-family: AlibabaPuHuiTiR;
                        @include res(font-size,24px,(20 / 24));
                        letter-spacing: 1px;
                        @include res(margin-top,20px,(3 /4));
                        @include res(margin-bottom,15px,(2 / 3));
                    }
                }
                p {
                    font-family: AlibabaPuHuiTiL;
                    @include res(font-size,16px,(14 / 16));
                    @include res(line-height,30px,(4 / 5));
                }
            }
        }
    }
    .section4 {
        background: url(./images/index-newsbg.png)no-repeat;
        background-size: cover;
        width: 100%;
        @include res(padding-top,80px,(3 / 8));
        @include res(padding-bottom,60px,(1 / 2));
        .container {
            position: relative;
            .news {
                .more{
                    position: absolute;
                    top: -10px;
                    @include res(right,0,(xs:10px));
                    font-family: AlibabaPuHuiTiL;
                    font-size: 20px;
                    color: #a7a7a7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border: solid 1px #a7a7a7;
                    transition: all .3s;
                    &:hover {
                        color: #1c419d;
                        border-color: #1c419d;
                    }
                }
                ul {
                    @include res(margin-top,40px);
                    li {
                        @include res(margin-bottom,40px,(1 / 2));
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            display: flex;
                            align-items: stretch;
                        }
                        .time {
                            color: #c5c5c5;
                            transition: all .3s;
                            @include res(margin-right,64px,(1 / 2));
                            h2 {
                                font-family: OriginalSurfer-Regular;
                                @include res(font-size,60px,(1 / 2)); 
                                @include res(line-height,50px,(1 / 2));
                                text-align: center;
                                margin-bottom: 3px;
                            }
                            span {
                                font-family: OriginalSurfer-Regular;
                                @include res(font-size,16px,(14 / 16));
                                letter-spacing: 1px;
                            }
                        }
                        .text {
                            font-family: AlibabaPuHuiTiL;
                            color: #7b7b7b;
                            transition: all .3s;
                            border-bottom: 1px solid #dcdcdc;
                            @include res(width,100%,(xs:50%));
                            flex: 1;
                            h2 {
                                @include res(font-size,20px,(18 / 20));
                                letter-spacing: 1px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                margin-bottom: 8px;
                            }
                            p {
                                @include res(font-size,16px,(14 / 16));
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        &:hover {
                            .time {
                                color: #1c419d;
                            }
                            .text {
                                color: #303030;
                                border-color: #1c419d;
                            }
                        }
                    }
                }
            }
            .notice {
                margin-top: 30px;
                @include res(height,112px,(60 / 112));
                display: flex;
                align-items: center;
                span {
                    display: block;
                    font-family: AlibabaPuHuiTiR;
                    @include res(font-size,26px,(20 / 26));
                    @include res(margin-right,35px,(20 / 35));
                }
                .swiper {
                    letter-spacing: 1px;
                    color: #303030;
                    @include res(height,25px);
                    line-height: 25px;
                    flex: 1;
                    .swiper-slide {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        a {
                            font-family: AlibabaPuHuiTiL;
                            @include res(font-size,20px,(16 / 20));
                        }
                    }
                    
                }
            }
        }
    }
}